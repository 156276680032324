.CalendarDay__selected_span {
  background: #ff5d7b !important;
  color: white !important;
  border: 1px solid #f8869b !important;
}
.CalendarDay__selected {
  background: #ff355a !important;
  color: white;
  border: 1px solid #f8869b !important;
}
.CalendarDay__selected:hover {
  background: #dd294a !important;
  color: white;
}
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #ffb0bf !important;
  color: #300c13 !important;
  border: 1px solid #f8869b !important;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #e4526d !important;
}

.DateInput_input__focused {
  border-bottom: 2px solid #ff4364 !important;
}
